<template>
  <div class="dashboard-container" v-loading="loading">
    <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="formdata"
        :rules="rules"
        label-width="200px"
      >
      <el-form-item label="服务名称" prop="serviceName" >
        <el-input v-model="formdata.serviceName" style="width:50%" />
        </el-form-item>
      
     
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="right">
          <el-button class="el-icon-edit" type="primary" @click="addSchoolInfo"
            >添加会员服务信息</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >
     
        
         <el-table-column
          align="center"
          prop="serviceName"
          label="服务名称"
          :show-overflow-tooltip="true"
        /> 

        <el-table-column align="center" label="操作" >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="updateSchoolInfo(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="delServiceMsg(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getServiceList"
      />
    </div>
  </div>
</template>

<script>
import {
 
  getServiceList,
  addServiceMsg,
 updateServiceMsg,
  delServiceMsg,
} from "../../request/http";

export default {
  data() {
    return {
      title: "", //弹出框标题
      loading: true, //加载动画
      showDialog: false, //显示隐藏
      list: [], // 列表
      total: 0, //总条数
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      formdata: {
        serviceName:'',
      },
 
      rules: {
        serviceName: [
          { required: true, message: "服务名称不能为空", trigger: "blur" },
        ],
      },
      
    };
  },
  created() {
    this.getServiceList();
  },
  methods: {
   
    //获取列表
    async getServiceList() {
      let { data } = await getServiceList(this.queryParams);
      this.list=data
      console.log(this.list);
      this.loading = false;
    },

  
    // 打开添加的窗口
    addSchoolInfo() {
      // this.imageUrl=null
       
      this.title = "添加会员服务信息";
      this.showDialog = true;
    },
    // 打开编辑类别的窗口
    updateSchoolInfo(val) {
      console.log(val);
    
      this.title = "编辑会员服务信息";
      this.formdata = {
        serviceId:val.serviceId,
        serviceName:val.serviceName,
     
      };
      this.showDialog = true;
    },

    // 确认
    btnOK() {
      this.$refs.perForm
        .validate()
        .then(() => {
          if (this.formdata.serviceId) {
           
            return updateServiceMsg(this.formdata);
          } else {
            return addServiceMsg(this.formdata);
          }
        })
        .then(() => {
          //  提示消息
          if (this.formdata.serviceId) {
            this.$message.success("编辑成功");
          } else {
            this.$message.success("新增成功");
            
          }

          this.getServiceList();

          this.showDialog = false;
        });
    },

    //弹窗取消函数
    btnCancel() {
 this.imageUrl=''
      this.formdata = {};
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },
    //删除信息
    async delServiceMsg(row) {
      try {
        await this.$confirm("确定要删除该会员服务吗");
        await delServiceMsg({ serviceId: row.serviceId });
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        );
        this.queryParams.page =
          this.queryParams.page > totalPage ? totalPage : this.queryParams.page;
        this.queryParams.page =
          this.queryParams.page < 1 ? 1 : this.queryParams.page;
        this.getServiceList();

        this.$message.success("删除成功");
      } catch (error) {
        console.log(error);
      }
    },

   
   
    
  },
};
</script>

<style scoped>
 
</style>